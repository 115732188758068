<template>
  <general-card class="pa-3">
    <v-row class="d-flex align-center">
      <v-col>
        <h6 class="text--disabled">Amount Due</h6>
        <h2 class="dashboard-blue">$2,231.00</h2>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-btn class="mr-2 custom-btn" small @click="credit = true"
          >Access Credit</v-btn
        >
        <v-btn class="mr-2 custom-btn" small @click="io = true">Raise IO</v-btn>
        <v-btn class="custom-btn" small @click="pay = true">Make Payment</v-btn>

        <v-dialog
          v-model="credit"
          width="550"
          transition="dialog-bottom-transition"
        >
          <credit-form />
        </v-dialog>

        <v-dialog
          v-model="io"
          width="550"
          transition="dialog-bottom-transition"
        >
          <io-form />
        </v-dialog>

        <v-dialog
          v-model="pay"
          width="550"
          transition="dialog-bottom-transition"
        >
          <payment-section />
        </v-dialog>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row class="py-5 pl-3">
      <v-sheet color="#707070" elevation="1" height="200" width="280"></v-sheet>
      <v-sheet
        color="#707070"
        elevation="1"
        height="200"
        width="280"
        class="mx-3"
      ></v-sheet>
      <v-card
        class="d-flex justify-center flex-column px-15"
        color="#F5F7FB"
        style="border: #64A7F4 solid 1px"
        @click="addCredit = true"
      >
        <v-icon color="#3371E2">mdi-plus</v-icon>
        <h5 class="text-uppercase mt-2">Add a Credit Card</h5>
      </v-card>
      <v-dialog
        v-model="addCredit"
        width="550"
        transition="dialog-bottom-transition"
      >
        <v-card class="pa-5">
          <add-credit>
            <v-btn class="custom-btn my-5" text @click="dialog = false">
              Add
            </v-btn>
          </add-credit>
        </v-card>
      </v-dialog>
    </v-row>
  </general-card>
</template>

<script>
import GeneralCard from "../Layouts/Cards/GeneralCard.vue";
import CreditForm from "./CreditForm.vue";
import IoForm from "./IoForm.vue";
import PaymentSection from "./PaymentSection.vue";
import AddCredit from "./AddCredit.vue";

export default {
  data: () => ({
    credit: false,
    io: false,
    pay: false,
    addCredit: false,
  }),
  components: {
    GeneralCard,
    CreditForm,
    IoForm,
    PaymentSection,
    AddCredit,
  },
};
</script>
