<template>
  <div>
    <v-row class="py-2 d-flex align-center">
      <v-col cols="4">
        <h6>Location and currency</h6>
      </v-col>
      <v-col cols="8">
        <v-row>
          <v-col>
            <v-select
              :items="countries"
              outlined
              dense
              append-icon="mdi-chevron-down"
              label="Country/region"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :items="currencies"
              outlined
              dense
              append-icon="mdi-chevron-down"
              label="Currency"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="py-2 d-flex align-center">
      <v-col cols="4">
        <h6>Card details</h6>
      </v-col>
      <v-col cols="8">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="card"
              single-line
              hide-details
              outlined
              rounded
              dense
              height="30px"
              color="#3371E2"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="cardNumber"
              single-line
              hide-details
              label="Card number"
              outlined
              rounded
              dense
              height="30px"
              color="#3371E2"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="4">
                <v-menu
                  ref="cardExpiry"
                  v-model="cardExpiry"
                  :close-on-content-click="false"
                  :return-value.sync="expiryDate"
                  transition="scale-transition"
                  offset-y
                  left
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="expiryDate"
                      label="MM/YY"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="expiryDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="cardExpiry = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.cardExpiry.save(expiryDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="cvv"
                  single-line
                  hide-details
                  label="CVV"
                  outlined
                  rounded
                  dense
                  height="30px"
                  color="#3371E2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  data: () => ({
    countries: ["Kenya", "Uganda", "Tanzania", "Rwanda"],
    currencies: ["US Dollar", "Ksh", "Tsh", "UgSh"],
    card: null,
    cardNumber: null,
    cardExpiry: null,
    expiryDate: null,
    cvv: null,
  }),
};
</script>
