<template>
  <div id="payment_summary">
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="6">
          <h3>Payment Summary</h3>
        </v-col>
        <v-col cols="6">
          <span class="float-right">
            <span class="mr-4 font-weight-bold dashboard-blue">Export</span>
            <v-icon color="#3371E2">mdi-export</v-icon>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="invoices"
      hide-default-footer
      disable-pagination
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.paid_by="{ item }">
        <span class="d-flex align-center">
          <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
          </v-list-item-avatar>
          <span>
            <h4>{{ item.paid_by.name }}</h4>
            <h5 class="text-uppercase text--disabled">
              {{ item.paid_by.status }}
            </h5>
          </span>
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.po_no="{ item }">
        <h4 v-if="item.po_no">{{ item.po_no }}</h4>
        <span v-else>__</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.settled_date="{ item }">
        <h4>{{ item.settled_date }}</h4>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.invoice_total="{ item }">
        <h4>$ {{ item.invoice_total }}</h4>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.method_of_payment="{ item }">
        <h4>{{ item.method_of_payment }}</h4>
      </template>
    </v-data-table>
    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    invoices: {
      type: Array,
      default: null,
    },
    headers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      display: 10,
      items: [5, 10, 15, 20],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 5, 10, 25, 50],
        total: 1,
        to: 1,
        from: 1,
        firstPage: 1,
        lastPage: 1,
        nextPage: 0,
        previousPage: 0,
      },
    };
  },
};
</script>
