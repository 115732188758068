<template>
  <div id="accounts">
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="6">
          <h3>Accounts</h3>
        </v-col>
        <v-col cols="6">
          <span class="float-right">
            <span class="mr-4 font-weight-bold dashboard-blue">Export</span>
            <v-icon color="#3371E2">mdi-export</v-icon>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="accounts"
      hide-default-footer
      disable-pagination
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.brand="{ item }">
        <router-link
          :to="{
            name: '',
            params: {
              id: item.id,
            },
          }"
        >
          <h4>{{ item.brand }}</h4>
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.contact_person="{ item }">
        <h4 class="text--disabled">
          {{ item.contact_person.name }} | {{ item.contact_person.email }}
        </h4>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.amount_due="{ item }">
        <h4>$ {{ item.amount_due }}</h4>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    accounts: {
      type: Array,
      default: null,
    },
    headers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      display: 10,
      items: [5, 10, 15, 20],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 5, 10, 25, 50],
        total: 1,
        to: 1,
        from: 1,
        firstPage: 1,
        lastPage: 1,
        nextPage: 0,
        previousPage: 0,
      },
    };
  },
};
</script>

<style scoped>
#accounts tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}

#accounts
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.05) !important;
}
</style>
