<template>
  <v-card class="pa-5">
    <v-radio-group v-model="options" mandatory class="mb-10">
      <v-radio label="Use saved card" value="saved"></v-radio>
      <v-radio label="Use a different card" value="different"></v-radio>
    </v-radio-group>
    <transition name="slide-fade">
      <v-card v-if="options === 'saved'" color="pa-5 pb-12">
        <v-radio-group v-model="card_no" mandatory class="mb-10" dense>
          <v-radio label="Visa · 9088" value="Visa-9088"></v-radio>
          <v-radio label="Visa · 1265" value="Visa-1265"></v-radio>
        </v-radio-group>

        <v-btn class="custom-btn float-right" text @click="dialog = false">
          Pay
        </v-btn>
      </v-card>
    </transition>
    <transition name="slide-fade">
      <v-card class="pa-5 mt-5" v-if="options === 'different'">
        <add-credit>
          <v-btn class="custom-btn my-5" text @click="dialog = false">
            Submit
          </v-btn>
        </add-credit>
      </v-card>
    </transition>
  </v-card>
</template>

<script>
import AddCredit from "./AddCredit.vue";

export default {
  data: () => ({
    options: null,
    card_no: null,
    added_cards: [
      ["Visa · 9088", "Visa-9088"],
      ["Visa · 1265", "Visa-1265"],
    ],
  }),
  components: {
    AddCredit,
  },
};
</script>
