<template v-slot:extension>
  <v-row class="d-flex align-center">
    <v-col cols="6 d-flex align-center">
      <!-- <v-btn depressed v-if="hasTabs" to="/facebook/campaigns">
        <v-icon left> mdi-arrow-left </v-icon>
        All Campaigns</v-btn
      > -->
      <span class="text--disabled font-weight-bold mt-2" v-if="product">{{
        product.name
      }}</span>
      <span v-if="hasTabs && isFacebook">
        <v-breadcrumbs :items="items"></v-breadcrumbs>
      </span>

      <v-tab
        v-for="([tab, link], i) in tabs"
        :key="i"
        :href="`#${link}`"
        class="mr-4"
        v-else
      >
        <span class="custom-transform-class tab-text" v-if="lavine_view"
          >Accounts Department</span
        >
        <span class="custom-transform-class tab-text" v-else>{{ tab }}</span>
      </v-tab>
    </v-col>
    <v-col class="d-flex align-center justify-end">
      <span class="float-right">
        <v-dialog
          v-if="$route.name === 'campaigns'"
          width="400"
          v-model="customDialog"
        >
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              depressed
              outlined
              color="#8cc73e"
              class="mr-2 custom-transform-class mt-1"
              v-bind="attrs"
              v-on="on"
              >Columns to Show</v-btn
            >
          </template> -->
          <v-card>
            <v-card-text>
              <v-list-item-group v-model="settings" multiple active-class="">
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Reach</v-list-item-title>
                      <v-list-item-subtitle
                        >Display or hide reach data</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>CTR</v-list-item-title>
                      <v-list-item-subtitle
                        >Display or hide CTR data</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Frequency</v-list-item-title>
                      <v-list-item-subtitle
                        >Display or hide frequency data</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Impressions</v-list-item-title>
                      <v-list-item-subtitle
                        >Display or hide impressions data</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Clicks</v-list-item-title>
                      <v-list-item-subtitle
                        >Display or hide clicks data</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Conversions</v-list-item-title>
                      <v-list-item-subtitle
                        >Display or hide conversions data</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-card-text>
          </v-card>
        </v-dialog>
        <span v-for="btn in btns" :key="btn">
          <v-btn
            depressed
            color="primary"
            class="ml-2"
            @click.prevent="btnClick(btn)"
          >
            {{ btn }}
          </v-btn>
        </span>
        <v-dialog
          v-model="dialog"
          width="300px"
          persistent
          v-if="
            $route.name !== 'campaigns' && !hasTabs && !isHome && !isFacebookDb
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              depressed
              color="primary"
              class="ml-2"
              v-if="isProgrammatic && user_role[0].name === 'SuperAdmin'"
              @click="chooseFiles()"
            >
              <input
                id="fileUpload"
                type="file"
                accept="text/csv"
                hidden
                v-on:change="onFileChange"
              />
              Upload DSP Stats
            </v-btn>

            <v-btn
              elevation="0"
              outlined
              v-bind="attrs"
              v-on="on"
              color="secondary"
              class="mr-2 custom-transform-class mt-1"
              v-if="false"
            >
              Select Date
            </v-btn>

            <!-- <v-btn
              small
              depressed
              outlined
              color="#8cc73e"
              class="mr-2 custom-transform-class mt-1"
              v-if="$route.name !== 'discovery' && $route.name !== 'facebook'&& $route.name !== 'user-lists-and-remarketing'"
              @click="handleToggle"
            > -->
            <!-- <span v-if="lavine_view">Toggle Single Account</span> -->
            <!-- <span v-else>Toggle Admin's View</span> -->
            <!-- </v-btn> -->
          </template>
          <v-date-picker v-model="dates" range flat>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
            <v-btn color="primary" text @click="dialog = false"> Save </v-btn>
          </v-date-picker>
        </v-dialog>
      </span>
    </v-col>
    <!-- <v-col cols="2" v-if="$route.name !== 'home'">
      <change-module @changeModule="changeModule($event)" />
    </v-col> -->
    <v-btn
      v-if="isFacebook && !superAdmin"
      outlined
      @click="setMediapalSocialCampaigns"
      small
      color="orange lighten-1"
    >
      Tradedesk Campaigns
    </v-btn>

    <v-col
      cols="2"
      class="d-flex align-center"
      v-if="(!hasTabs && isFacebook) || isFacebookDb"
    >
      <v-select
        outlined
        dense
        v-model="ad_account_id"
        label="Change ad account"
        :items="ad_accounts"
        item-text="name"
        item-value="id"
        @input="emitAdAccountId"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
// import ChangeModule from "./../../ChangeModule.vue";

export default {
  data: () => ({
    dates: ["2021-10-10", "2021-10-20"],
    dialog: false,
    customDialog: false,
    hasTabs: false,
    settings: [],
    lavine_view: false,
    isFacebook: false,
    isProgrammatic: false,
    isHome: false,
    isFacebookDb: false,
    ad_account_id: "",
    items: [
      {
        text: "Facebook Campaigns",
        disabled: false,
        href: "#/facebook/campaigns",
      },
      {
        text: "Single Campaign",
        disabled: true,
        href: "#",
      },
    ],
    campaign_id: "",
    dsp_csv_data: [],
    dsp_csv_fields: [
      "#Day",
      "Hour",
      "Creative ID",
      "Impressions",
      "Clicks",
      "Spend (bidder currency)",
    ],
    missing_fields: [],
    upload_data: { data: [] },
  }),
  watch: {
    _ad_account_id() {
      this.ad_account_id = this._ad_account_id.includes("act_")
        ? this._ad_account_id
        : `act_${this._ad_account_id}`;
    },
    dsp_csv_data() {
      this.csvTojson(this.dsp_csv_data);
    },
  },
  // components: {
  //   ChangeModule,
  // },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  mounted() {
    this.campaign_id = this.$route.params.id;

    this.$route.params.id ? (this.hasTabs = true) : null;
    this.$route.name == "facebook" || this.$route.name == "facebook-campaign"
      ? (this.isFacebook = true)
      : null;
    this.$route.name == "tradedesk" ||
      this.$route.path == "/tradedesk/reporting"
      ? (this.isProgrammatic = true)
      : null;
    this.$route.name == "home" ? (this.isHome = true) : null;
    this.$route.name == "Facebook Dashboard"
      ? (this.isFacebookDb = true)
      : null;
  },
  props: {
    tabs: {
      type: Array,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    btns: Array,
    ad_accounts: Array,
    _ad_account_id: String,
    user_role: Array,
  },
  methods: {
    setMediapalSocialCampaigns() {
      this.$emit("setMediapalSocialCampaigns");
    },
    btnClick(value) {
      if (value === "Add Campaign" && this.isFacebook)
        this.$router.push("/facebook/create-campaign");
      if (value === "Add Campaign" && this.isFacebookDb)
        this.$router.push("/facebook/create-campaign");
      if (value === "Add Campaign" && !this.isFacebook)
        this.$router.push("/tradedesk/create-campaign");
      if (value == "Delete Campaign" && !this.isFacebook)
        this.$router.push("/tradedesk/delete-campaign");
      if (value === "Add Transsion Campaign" && !this.isFacebook)
        this.$router.push({
          name: "new-transsion-campaign",
        });
      if (value === "Add Opera Campaign" && !this.isFacebook)
        this.$router.push({
          name: "new-opera-campaign",
        });
      if (value === "Add Phoenix Campaign" && !this.isFacebook)
        this.$router.push({
          name: "new-phoenix-campaign",
        });
      if (value === "Add Truecaller Campaign" && !this.isFacebook)
        this.$router.push({
          name: "new-truecaller-campaign",
        });

      if (value === "Edit") {
        this.$router.push({
          name: "update-facebook-campaign",
          params: { id: this.campaign_id },
        });
      }
      if (value == "Delete") {
        this.$emit("deleteCampaign", this.campaign_id);
      }
      if (value === "Pause" && this.isFacebook) {
        this.$emit("pauseCampaign", this.campaign_id)
      }

    },
    handleToggle() {
      this.lavine_view = !this.lavine_view;
      this.$emit("toggleLavine");
    },
    emitAdAccountId() {
      this.$emit("setAdAccountId", this.ad_account_id.substring(4));
    },
    changeModule(selected_module) {
      this.$emit("changeModule", selected_module);
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createCSVFile(files[0]);
    },
    createCSVFile(file) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.csv_file = e.target.result;
        this.is_csv_file_uploaded = true;
      };
      reader.readAsText(file);
      reader.addEventListener("load", (e) => {
        const data = e.target.result;
        this.dsp_csv_data = data;
      });
    },
    csvTojson(csv) {
      var lines = csv.split("\n");
      var result = [];
      var headers = lines[0].split(",").map((str) => {
        return str.replace(/"/g, "");
      });
      this.dsp_csv_fields.forEach((field) => {
        if (!headers.includes(field)) {
          this.missing_fields.push(field);
        }
      });
      if (this.missing_fields.length > 0) {
        this.$notify({
          title:
            "Invalid File. Day, Hour, Creative ID, Impressions, Clicks and Spend required.",
          group: "errors",
        });
        this.missing_fields = [];
      }
      if (this.missing_fields.length === 0) {
        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var row = lines[i],
            queryIdx = 0,
            startValueIdx = 0,
            idx = 0;
          if (row.trim() === "") {
            continue;
          }
          while (idx < row.length) {
            /* if we meet a double quote we skip until the next one */
            var c = row[idx];
            if (c === '"')
              do {
                c = row[++idx];
              } while (c !== '"' && idx < row.length - 1);

            if (
              c === "," ||
              /* handle end of line with no comma */ idx === row.length - 1
            ) {
              /* we've got a value */
              var value = row.substr(startValueIdx, idx - startValueIdx).trim();
              /* skip first double quote */
              if (value[0] === '"') {
                value = value.substr(1);
              }
              /* skip last comma */
              if (value[value.length - 1] === ",") {
                value = value.substr(0, value.length - 1);
              }
              /* skip last double quote */
              if (value[value.length - 1] === '"') {
                value = value.substr(0, value.length - 1);
              }
              var key = headers[queryIdx++];
              obj[key] = value;
              startValueIdx = idx + 1;
            }
            ++idx;
          }

          result.push(obj);
        }
        this.upload_data.data = result;
      }
      this.$emit("setUploadData", this.upload_data);
    },
  },
};
</script>
