<template>
  <v-tabs slider-color="#8cc73e" background-color="#F5F7FB" class="pt-3">
    <main-topbar :tabs="tabs" @toggleLavine="toggleLavineView" />
    <v-tab-item value="billing">
      <transition name="slide-fade">
        <amount-due v-if="!lavine_view" />
      </transition>

      <transition name="slide-fade">
        <general-card class="mt-3" v-if="lavine_view">
          <payment-table :headers="headers"/>
        </general-card>
      </transition>

      <transition name="slide-fade">
        <general-card class="mt-3" v-if="!lavine_view">
          <client-payment-table
            :headers="client_headers"
          />
        </general-card>
      </transition>

      <transition name="slide-fade">
        <general-card class="mt-3" v-if="lavine_view">
          <accounts-table :headers="account_headers"/>
        </general-card>
      </transition>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import PaymentTable from "../../components/Tables/PaymentTable.vue";
import ClientPaymentTable from "../../components/Tables/ClientPaymentTable.vue";
import AccountsTable from "../../components/Tables/AccountsTable.vue";
import GeneralCard from "../../components/Layouts/Cards/GeneralCard.vue";
import MainTopbar from "../../components/Layouts/Topbars/MainTopbar.vue";
import AmountDue from "../../components/Billing/AmountDue.vue";

export default {
  data: () => ({
    lavine_view: false,
    tabs: [["Kenya", "billing"]],
    account_headers: [
      {
        text: "Brand",
        sortable: false,
        value: "brand",
        class: "header-bg",
      },
      {
        text: "Contact",
        value: "contact_person",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Amount Due",
        align: "end",
        value: "amount_due",
        sortable: false,
        class: "header-bg",
      },
    ],
    headers: [
      {
        text: "Invoice No.",
        sortable: false,
        value: "invoice_no",
        width: "115px",
        class: "header-bg",
      },
      {
        text: "PO NO.",
        value: "po_no",
        width: "100px",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Client",
        value: "client",
        width: "120px",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Campaign Name",
        value: "campaign_name",
        width: "220px",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Invoice Date",
        value: "invoice_date",
        width: "130px",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Invoice Total",
        value: "invoice_total",
        width: "130px",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Status",
        value: "status",
        width: "110px",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Settled Date",
        value: "settled_date",
        width: "130px",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Method of Payment",
        value: "method_of_payment",
        width: "180px",
        sortable: false,
        class: "header-bg",
      },
    ],
    client_headers: [
      {
        text: "Paid By",
        sortable: false,
        value: "paid_by",
        class: "header-bg",
      },
      {
        text: "PO NO.",
        sortable: false,
        value: "po_no",
        class: "header-bg",
      },
      {
        text: "Payment Date",
        value: "settled_date",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Amount",
        value: "invoice_total",
        sortable: false,
        class: "header-bg",
      },
      {
        text: "Payment Method",
        align: "end",
        value: "method_of_payment",
        sortable: false,
        class: "header-bg",
      },
    ],
  }),
  components: {
    PaymentTable,
    GeneralCard,
    AccountsTable,
    MainTopbar,
    AmountDue,
    ClientPaymentTable,
  },
  methods: {
    toggleLavineView() {
      this.lavine_view = !this.lavine_view;
    },
  },
};
</script>

<style>
.header-bg {
  background-color: #ecf4f6;
  text-transform: uppercase;
  font-size: 0.7rem;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
