<template>
  <v-card class="pt-5" id="io-form">
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Amount</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="amount"
          prepend-inner-icon="mdi-currency-usd"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Duration</h6>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-menu
              ref="menuFrom"
              v-model="menuFrom"
              :close-on-content-click="false"
              :return-value.sync="selectedFromDate"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="selectedFromDate"
                  append-icon="mdi-calendar"
                  label="Start Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="selectedFromDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuFrom = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFrom.save(selectedFromDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              ref="menuTo"
              v-model="menuTo"
              :close-on-content-click="false"
              :return-value.sync="selectedToDate"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="selectedToDate"
                  append-icon="mdi-calendar"
                  label="End Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="selectedToDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuTo = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuTo.save(selectedToDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Description</h6>
      </v-col>
      <v-col>
        <v-textarea solo name="input-7-4" v-model="description"></v-textarea>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="custom-btn my-5" text @click="dialog = false">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    checkbox: false,
    amount: null,
    menuFrom: null,
    selectedFromDate: null,
    menuTo: null,
    selectedToDate: null,
    description: "",
  }),
};
</script>

<style>
#io-form
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__prepend-inner {
  margin-top: 0px !important;
}

#io-form .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 0 0 4px !important;
}

#io-form .v-input__icon .v-icon.v-icon {
  color: #3371e2 !important;
}
</style>
