<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-5 text-center">
      Customer Information Form
    </v-card-title>

    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Company Name</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="companyName"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Telephone No.</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="tel"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Email Address</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="email"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Postal Address</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="postal"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Physical Location</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="location"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>Nature of Business</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="business"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>PIN Number (also provide ITAX generated copy of your PIN)</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="pin"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3 d-flex align-center">
      <v-col cols="4">
        <h6>
          Business Registration Number (provide copy of registration/
          incorporation Certificate)
        </h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="registration"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-6"></v-divider>
    <v-row class="px-6 py-3">
      <v-checkbox
        class="mt-3 ml-3 terms"
        v-model="checkbox"
        label="I hereby confirm that all the above information is true and correct."
      ></v-checkbox>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="custom-btn my-5" text @click="dialog = false">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    checkbox: false,
    companyName: "",
    tel: null,
    email: null,
    postal: null,
    location: null,
    business: null,
    pin: null,
    registration: null,
    
  }),
};
</script>

<style>
.terms .v-label {
  font-size: 0.8rem !important;
  font-weight: bold
} 
</style>
